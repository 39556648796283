import {
  arrayOf as arrayOfType,
  bool as boolType,
  client,
  extend,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
} from '@thd-nucleus/data-sources';

import { CallToActionDataModel } from './CallToActionDataModel';
import { PromotionProductsDataModel } from './PromotionProductsDataModel';

export const PromotionProductsAnchorDataModel = extend(
  {
    product: params({ itemId: stringType().isRequired() }).shape(extend(
      {
        dataSources: stringType(),
        itemId: stringType(),
        pricing: params({ storeId: stringType().isRequired() }).shape({
          alternate: shapeType({
            bulk: shapeType({
              value: numberType({ float: true }),
            }),
          }),
          value: numberType({ float: true }),
          conditionalPromotions: arrayOfType(shapeType({
            dates: shapeType({
              start: stringType(),
              end: stringType(),
            }),
            description: shapeType({
              shortDesc: stringType(),
              longDesc: stringType(),
            }),
            experienceTag: stringType(),
            subExperienceTag: stringType(),
            // we need to trim this model up. Be careful with
            // Promotion component short description
            eligibilityCriteria: arrayOfType(shapeType({
              itemGroup: stringType(),
              minPurchaseAmount: numberType({ float: true }),
              minPurchaseQuantity: numberType({ float: true }),
              relatedSkusCount: numberType(),
              omsSkus: arrayOfType(stringType()),
            })),
            reward: shapeType({
              tiers: arrayOfType(shapeType({
                minPurchaseAmount: numberType({ float: true }),
                minPurchaseQuantity: numberType({ float: true }),
                rewardPercent: numberType({ float: true }),
                rewardAmountPerOrder: numberType({ float: true }),
                rewardAmountPerItem: numberType({ float: true }),
                rewardFixedPrice: numberType({ float: true }),
                maxAllowedRewardAmount: numberType({ float: true }),
                maxPurchaseQuantity: numberType({ float: true }),
              })),
            }),
            nvalues: arrayOfType(stringType()),
            brandRefinementId: stringType(),
          })),
          preferredPriceFlag: boolType(),
        }),
        fulfillment: client(params({ storeId: stringType() }).shape({
          fulfillmentOptions: arrayOfType(shapeType({
            fulfillable: boolType(),
            type: stringType(),
            services: arrayOfType(shapeType({
              type: stringType(),
              locations: arrayOfType(shapeType({
                isAnchor: boolType(),
                locationId: stringType(),
                inventory: shapeType({
                  isOutOfStock: boolType(),
                  quantity: numberType(),
                }),
              })),
            })),
          })),
        })),
        media: shapeType({
          image: shapeType({
            url: stringType(),
          }).client(),
          images: arrayOfType(shapeType({
            url: stringType(),
            sizes: arrayOfType(stringType()),
            type: stringType(),
            subType: stringType(),
          })),
        }),
        identifiers: shapeType({
          brandName: stringType(),
          productType: stringType(),
        }),
      },
      CallToActionDataModel.product,
    )),
  },
  PromotionProductsDataModel
);
